import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import './src/styles/fonts.css';
import ThemeContextProvider from './src/contexts/ThemeContext';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <ThemeContextProvider>{element}</ThemeContextProvider>;
};
