import React, { createContext, useEffect, useState } from 'react';

type ThemeContextProps = {
  darkMode: null | boolean;
  toggleDarkMode?: () => void | undefined;
};

// https://github.com/gatsbyjs/gatsby/issues/19255
const initState: ThemeContextProps = {
  darkMode: null,
};

export const ThemeContext = createContext(initState);

const ThemeContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [darkMode, setDarkMode] = useState<null | boolean>(null);
  const { theme } = localStorage;

  useEffect(() => {
    const setTheme = () => {
      if (
        theme === 'dark' ||
        (!('theme' in localStorage) &&
          window.matchMedia('(prefers-color-scheme: dark)').matches)
      ) {
        document.documentElement.classList.add('dark');
        setDarkMode(true);
      } else {
        document.documentElement.classList.remove('dark');
        setDarkMode(false);
      }
    };
    setTheme();
  }, [theme]);

  const toggleDarkMode = () => {
    if (darkMode) {
      localStorage.setItem('theme', 'light');
      setDarkMode(false);
    } else {
      localStorage.setItem('theme', 'dark');
      setDarkMode(true);
    }
  };

  const value = { darkMode, toggleDarkMode };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
